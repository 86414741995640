<template>
  <div>
    <v-container class="main-container d-flex flex-wrap">
      <div class="col-12 col-mm-8 pa-1 mt-8 ">

        <TopContent :importantcontent="false" ></TopContent>

        <p class="my-6 side-content__title">تماس با ما</p>

        <div class="mb-6" style="color: white" v-html="contacttxt">

        </div>

        <v-text-field
            dense
            label="نام و نام خانوادگی"
            outlined
            filled
            v-model="fullName"
            dark
        ></v-text-field>

        <v-text-field
            label="شماره تماس"
            filled
            dense
            outlined
            v-model="tell"
            dark
        ></v-text-field>

        <v-text-field

            label="ایمیل"
            outlined
            dense
            filled
            dark
            v-model="email"
        ></v-text-field>

        <v-textarea

            label="متن پیام"
            outlined
            filled
            v-model="text"
            dark

        ></v-textarea>

        <v-btn
            class="d-block mr-auto primary"
            :loading="loading"
            @click="sendmessage"
        >
          ارسال پیام
        </v-btn>


      </div>
      <div class="col-12 col-mm-4 py-6 px-lg-6 px-2">

        <LiveSearch></LiveSearch>

        <LastPosts></LastPosts>

        <RelatedPosts></RelatedPosts>

      </div>
    </v-container>


  </div>
</template>

<script>

import RelatedPosts from "../components/RelatedPosts";
import LastPosts from "../components/LastPosts";
import LiveSearch from "../components/LiveSearch";
import TopContent from "../components/TopContent";
import axios from "axios";
export default {
  name: 'App',
  components : {
    RelatedPosts,
    LastPosts,
    LiveSearch,
    TopContent
  },
  metaInfo() {
    return {
      title:'تماس با ما',
      link: [{rel: 'canonical', href: this.$store.state.url+'/contactus'}]
    }
  },
  data() {
    return {
      text : '',
      email : '',
      tell : '',
      fullName : '',
      loading : false,
      contacttxt : '<div style="height: 300px" ></div>',
    }
  },
methods : {
  sendmessage(){
    this.loading = true;
    axios.post(this.$store.state.api + 'contactus', {
      text : this.text,
      Email : this.email,
      tell : this.tell,
      fullName : this.fullName,
    }).then(response => {
      if(response.data.result){
        this.text = '';
        this.email = '';
        this.tell = '';
        this.fullName = '';
        this.loading = false;
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'پیام شما ارسال شد';
      }
    }).catch(error=>{
      this.loading = false;
      this.$store.state.notify.is = true;
      this.$store.state.notify.text = error;
    })
  }
},
  created() {
    axios.get(this.$store.state.api + 'contactustext').then(response=>{
      this.contacttxt = response.data.data;
    });
  }
};
</script>
